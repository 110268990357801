<template>
  <div class="card">
    <img class="card-img-top" v-if="asset != null && asset.endsWith('.jpg')" :src="asset">
    <div v-else class="embed-responsive embed-responsive-16by9">
      <video autoplay muted playsinline loop class="embed-responsive-item w-100">
        <source :src="asset" type="video/mp4">
      </video>
    </div>
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <p class="card-text">{{ description }}</p>
      <div class="d-flex align-items-center flex-row">
        <div class="w-100 tech-stack">
          <font-awesome-icon v-for="tech in stack" :icon="['fab', tech]" fixed-width class="spacing" />
        </div>
        <div class="w-50 text-right btn-group">
          <a :href="'https://github.com/'+ github" v-if="github != null" class="btn btn-outline-secondary"><font-awesome-icon :icon="['fab', 'github']" fixed-width /></a>
          <a :href="url" v-if="url != null" class="btn btn-outline-primary"><font-awesome-icon icon="external-link-alt" fixed-width /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "PreviousProject",
    props: {
      title: {
        type: String
      },
      description: {
        type: String
      },
      asset: {
        type: String
      },
      stack: {
        type: Array
      },
      github: {
        type: String
      },
      url: {
        type: String
      }
    }
}
</script>
<style scoped>
.tech-stack {
  font-size: 2rem;
}
</style>