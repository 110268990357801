import { createApp } from 'vue'

import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBootstrap, faGithub, faJava, faNode, faPhp, faTwitter, faVuejs } from '@fortawesome/free-brands-svg-icons'
import { faCamera, faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGithub);
library.add(faTwitter)
library.add(faEnvelope);
library.add(faCamera);
library.add(faVuejs);
library.add(faBootstrap);
library.add(faExternalLinkAlt);
library.add(faJava);
library.add(faPhp);
library.add(faNode);

createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
