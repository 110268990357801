<template>
    <div class="modal d-block" role="dialog" v-if="open">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-self-center">
                    <vue-recaptcha siteKey="6LdA4BUUAAAAAGH2efEtSfSgivja1NM6ueAyZgZL" @verify="loadEmail" v-if="email == null" />
                    <p v-else><a :href="'mailto:' + email">{{ email }}</a></p>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" @click="close">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VueRecaptcha from 'vue3-recaptcha2';
export default {
    name: "EmailModal",
    components: {
        VueRecaptcha
    },
    props: {
        open: {
            type: Boolean
        }
    },
    data() {
        return {
            email: null
        }
    },
    methods: {
        async loadEmail(response) {
            let reqResponse = await fetch("https://thomas.gg/api/email", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({captcha: response})
            }).then(response => response.json()).then(data => this.email = data.email);
        },
        close() {
            this.$emit("clicked");
        }
    }
}
</script>