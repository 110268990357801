<template>
  <div class="row">
    <div class="col-12 bg-blue-pattern d-flex justify-content-center">
      <div class="card w-75 p-4 m-5">
        <h3 class="card-title text-dark-blue text-center">NameMC Skinart Tool</h3>
        <p class="m-0 py-3">This tool is used to split up an image that is 72 pixels wide and 24 pixels in height into individual skin files that you can upload in order to create cool looking 'skin art' using the skin history feature on NameMC. To see an example of the end result, see <a href="https://i.thomas.best/0806ec80-41a9-4c27-87b9-51860559b67f.png" class="text-dark-blue">here.</a></p>
        <p>Whilst the instructions inside the completed zip file are fairly self-explanatory, there are several YouTube videos available online which show the tool in action and what to do with the output. Check some of them out <a href="https://www.youtube.com/watch?v=ObbpV1UNZDc" class="text-dark-blue">here</a>, <a href="https://www.youtube.com/watch?v=IH6qBZEZjhI" class="text-dark-blue">here</a> or <a href="https://www.youtube.com/watch?v=dgsyb0fYjWI" class="text-dark-blue">here</a>.</p>
        <p>The latest update of this tool allows you to provide a base skin rather than the default blue from the previous iteration of my site. Thousands of skinarts have been generated using this tool - thank you! Please let me know if you have any suggestions.</p>
        <div class="input-group">
          <label class="input-group-btn my-0">
            <span class="btn btn-large btn-outline-secondary rounded-0">Browse...
              <input class="d-none" type="file" accept=".jpg,.png" @change="loadSkinartFile">
            </span>
          </label>
          <input type="text" class="form-control rounded-0" readonly placeholder="Upload skinart (72x24px)">
          <label class="input-group-btn my-0">
            <span class="btn btn-large btn-outline-secondary rounded-0">Browse... 
              <input class="d-none" type="file" accept=".jpg,.png" @change="loadBaseFile">
            </span>
          </label>
          <input type="text" class="form-control rounded-0" readonly placeholder="Upload base skin (optional, 64x64px)">
        </div>
        <div class="row">
          <div class="col-6">
          <label for="skinart_uuid">Optionally, you may also provide your Minecraft UUID below. At some point, I may create a gallery of skinarts made here.</label>
            <input id="skinart_uuid" v-model="supplied_uuid" type="text" class="form-control rounded" placeholder="Minecraft UUID (with dashes)" pattern="\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b">
          </div>
          <div class="col-6">
            <button class="btn btn-large btn-outline-primary mt-1" @click="generate()" :disabled="skinart_file == null || default_base == null">Generate!</button>
            <div v-if="generated">
              <div class="progress mt-1">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="'width: ' + ((skins_generated/27)*100)+ '%'"></div>
              </div>
              <small>{{ skins_generated }} out of 27 skins generated</small>
            </div>
            <p class="text-danger" v-if="error">{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imageToSlices from "image-to-slices";
import JSZip from "jszip";
import { saveAs } from "file-saver";

export default {
  name: "NameMCSkinart",
  data() {
    return {
      generated: false,
      skins_generated: 0,
      skinart_file: null,
      base_file: null,
      default_base: null,
      error: null,
      supplied_uuid: null
    }
  },
  mounted() {
    let defaultBase = new Image;
    defaultBase.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwAAADsABataJCQAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4yMfEgaZUAAAGnSURBVHhe7ZlBSgRBDEV74T08gQh6HFF3HtuVa08w0Cr8xtC8miI9UKmqzoOHIPnhpxxm41Lj+WNdb1FrxoWO8qg140JHedSacaGjPGrNuNBRHrVmXOgoj1ozLnSUR60ZFzrKo9b0C5XefHz/Xu8/n65KOY+qEQeV2nx4+cKjrZTzqBpxUKnNfIB8gHwAPNpKOY+qEQeV2jz9A/xJR1sp41E1+oFKWjVWhDIetaYIZawaOw4ttWqsCGU8ak0Rylg1dhxaatVYEcp41JoilLFq7Di01KqxIpTxqDVFKGPVmIPX39DMVqHQTFah0ExWoZD17cK/H8UqFBpV+mNV2QdGcn/w6R5g72kf4Nr3VBUKzWQVCg3uerf8/6yyC8/i9gi68gq74Az6PgFJkiRJkiRJkiRJkiQ3Qv+wtGqsCGWsGusXKm3VWBHKWDXWL1TaqrEilLFqrF+otFVjRShj1Vg/UMmWqkYcVKqlqhEHlWqpasRBpVqqGnFQqZaqRhxUqqWqEQeVaqlqxEGlWqoacVCplqpGHFSqpaoRB5VqqWrEQaVaqhoHWZYfQ1HI1X0yf2sAAAAASUVORK5CYII=";
    defaultBase.onload = (e) => this.default_base = defaultBase;
  },
  methods: {
    loadSkinartFile(ev) {
      this.error = null;
      this.skinart_file = null;
      let reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      reader.onload = (e) => {
        let img = new Image;
        img.src = e.target.result;
        img.onload = () => {
          if(img.naturalHeight != 24 || img.naturalWidth != 72) return this.error = "Skinart must be 72x24 pixels (your image is " + img.naturalWidth + "x" + img.naturalHeight + ")";
          this.skinart_file = e.target.result;
        }
      }
    },
    loadBaseFile(ev) {
      this.error = null;
      this.base_file = null;
      let reader = new FileReader();
      reader.readAsDataURL(ev.target.files[0]);
      reader.onload = (e) => {
        let img = new Image;
        img.src = e.target.result;
        img.onload = () => {
          if(img.naturalHeight != 64 || img.naturalWidth != 64) return this.error = "Skin must be 64x64 pixels (your image is " + img.naturalWidth + "x" + img.naturalHeight + ")";
          this.base_file = img;
        }
      }
    },
    generate() {
      let uuidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');
      if(this.supplied_uuid != null && this.supplied_uuid.match(uuidRegex) == null) return alert("Invalid Minecraft UUID - should contain dashes");
      this.skins_generated = 0;
      this.generated = true;
      let baseSkin = this.base_file;
      if(baseSkin == null) baseSkin = this.default_base;
      imageToSlices(this.skinart_file, [8, 16], [8, 16, 24, 32, 40, 48, 56, 64, 72], {saveToDataUrl: true}, (dataUrlList) => {
        let zip = new JSZip;
        zip.file("README.txt", "NameMC skin art generator - thomas.gg\r\n\r\nUpload each skin from Skin-27.png all the way down to Skin-1.png.\r\nMake sure you visit NameMC on each upload AND you make sure NameMC cached the skin before uploading the next skin.\r\n\r\nThanks for using my tool - be sure to tell your friends about it!");
        
        let generateSkin = (i) => {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          ctx.canvas.width = 64;
          ctx.canvas.height = 64;
          ctx.drawImage(baseSkin, 0, 0);
          /* Prevent duplicate skins by adding a random coloured pixel */
          ctx.fillStyle = "rgba(0, 0, " + (220+i) + ", 1);";
          ctx.fillRect(53, 29, 2, 2);
          /* Present skin */
          let img = new Image;
          img.onload = () => {
            ctx.drawImage(img, 8, 8);
            zip.file("Skin-" + (i+1) + ".png", canvas.toDataURL("image/png", [0,1]).split(",")[1], {base64:true});
            this.skins_generated++;
            if(this.skins_generated == 27) {
              zip.generateAsync({
                type: "blob"
              }).then(async (content) => {
                saveAs(content, "Skinart.zip");
                await fetch("https://thomas.gg/api/skinart", {
                  method: "POST",
                  headers: {
                      "Content-Type": "application/json"
                  },
                  body: JSON.stringify({uuid: this.supplied_uuid})
                }).catch((exception) => {
                  console.error("Exception submitting to NameMC skinart analytics: " + exception)
                })
              })
            }
          }
          img.src = dataUrlList[i].dataURI;
        }
        for(let i = 0; i < dataUrlList.length; i++) generateSkin(i);
      });
    }
  }
}
</script>