<template>
    <div class="container">
        <div class="row py-4">
            <div class="col-12">
                <h2 class="text-center text-dark-blue">Current &amp; Previous Projects</h2>
            </div>
            <div class="col-lg-4 col-sm-12 mt-2 mb-2" v-for="project in projects">
                <previous-project :title="project.title" :description="project.description" :stack="project.stack" :url="project.url" :github="project.github" :asset="project.asset" />
            </div>
        </div>
    </div>
</template>
<script>
import PreviousProject from "../components/PreviousProject.vue";

export default {
    name: "PreviousProjects",
    components: {PreviousProject},
    data() {
        return {
            projects: [
                {
                    title: "Photo Sorter",
                    description: "Electron app to automate sorting photos by the aircraft registration using Gcloud OCR and replays of ADSB data. AI-assisted watermarking enables photos to be sorted and watermarked rapidly.",
                    stack: ["vuejs", "bootstrap", "node"],
                    asset: "https://i.thomas.gg/f45fdb13-2747-4dfb-8f4b-5793cc2516c0.jpg"
                },
                {
                    title: "pics.thomas.gg",
                    description: "A photo gallery specifically designed for organising photos of aircraft, with the ability to build and perform complex searches to find photos as well as tracking progress towards photographing a fleet.",
                    stack: ["vuejs", "bootstrap", "php"],
                    url: "https://pics.thomas.gg",
                    github: "period/pictureviewer",
                    asset: "https://i.thomas.gg/78709939-b589-4a81-8501-ab0fca457538.mp4"
                },
                {
                    title: "lhrwx",
                    description: "A calendar oriented web-app that forecasts which runway Heathrow airport will use at a given time based on forecasted weather and the published runway alternation schedule.",
                    stack: ["vuejs", "bootstrap"],
                    github: "period/lhrwx",
                    url: "https://lhrwx.thomas.gg",
                    asset: "https://i.thomas.gg/18ecc1b7-af74-4a9a-a675-4d25df420887.jpg"
                },
                {
                    title: "Tweet Monitor",
                    description: "Regular polling of tweets for 30 days after they are tweeted to collect their metrics, which are then presented on several charts to allow one to determine easily what makes a tweet perform well.",
                    stack: ["vuejs", "bootstrap", "php", "node"],
                    github: "period/tweetmonitor-frontend",
                    asset: "https://i.thomas.gg/1e913f7c-5647-495a-8cab-fbdb33a04d95.jpg"
                },
                {
                    title: "XP Party",
                    description: "Website and accompanying Minecraft mod to enable the automated hosting of XP parties on the Hypixel Minecraft server, regularly supporting several hundred concurrent websocket connections.",
                    stack: ["bootstrap", "php", "java", "node"],
                    url: "https://xpparty.club",
                    asset: "https://i.thomas.gg/69d8b70c-e455-4126-bc77-e42d3f94388e.jpg"
                },
                {
                    title: "Christmas Lights",
                    description: "Synchronisation of ~5000 pixels to music using xLights. Total show automation achieved using various custom-made Node addons that sit in front of the E131 output from xLights.",
                    stack: ["node"],
                    asset: "https://i.thomas.gg/1312f3c2-ed97-4e03-90fd-05e0530c0c93.jpg"
                }
            ]
        }
    }
}
</script>